module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"880867332743680"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/syukrie/Desktop/Projects/pesantren-dps-codespace/node_modules/gatsby-remark-images","id":"a027f21e-78b2-5007-be39-3778fef3de1a","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/home/syukrie/Desktop/Projects/pesantren-dps-codespace/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":2048,"linkImagesToOriginal":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/syukrie/Desktop/Projects/pesantren-dps-codespace/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.25.0","modulePath":"/home/syukrie/Desktop/Projects/pesantren-dps-codespace/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/syukrie/Desktop/Projects/pesantren-dps-codespace/node_modules/gatsby-remark-smartypants","id":"d48194e5-b344-5f22-b93d-76045ee44e88","name":"gatsby-remark-smartypants","version":"5.25.0","modulePath":"/home/syukrie/Desktop/Projects/pesantren-dps-codespace/node_modules/gatsby-remark-smartypants/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"remarkPlugins":[{"resolve":"remark-slug"},{"resolve":"@mapbox/rehype-prism"}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/syukrie/Desktop/Projects/pesantren-dps-codespace","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pesantren Property Syariah SMK Digital Marketing","short_name":"pesantren-property-syariah","start_url":"/","background_color":"#ffffff","theme_color":"#660099","display":"minimal-ui","icon":"src/images/gatsby/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"85e796a8822d6d4c3dc9410a881eb973"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
